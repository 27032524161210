import { useState } from "react";
import Snowflake from "./snowflake"; // Adjust the import path as necessary
import debounce from "lodash/debounce";

const useSnowflakes = () => {
  const [snowflakes, setSnowflakes] = useState<JSX.Element[]>([]);

  const createSnowflake = (e: React.MouseEvent) => {
    const x = e.clientX;
    const y = e.clientY - 500;

    const duration = Math.random() * 2 + 2; // Random duration

    const newSnowflake = (
      <Snowflake
        key={Date.now() + Math.random()} // Unique key
        left={x}
        top={y}
        duration={duration}
        onAnimationEnd={() => {
          setSnowflakes((prev) => prev.filter((_, index) => index !== 0));
        }}
      />
    );

    setSnowflakes((prev) => [...prev, newSnowflake]);
  };
  const debouncedCreateSnowflake = debounce(createSnowflake, 50); // Adjust the debounce time as needed

  return { snowflakes, createSnowflake: debouncedCreateSnowflake };
};

export default useSnowflakes;

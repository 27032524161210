import { cn } from "@/lib/utils";
import { ReactNode } from "react";

const GalaxyBorderCard = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const combinedClass = className ?? `rounded-2xl `;
  return (
    <div
      className={cn(
        combinedClass,
        `hover:animate-border-angle w-full border bg-surface-variant
      hover:border-transparent hover:bg-on-surface-variant 
      hover:[background:linear-gradient(45deg,#ffffff,theme(colors.slate.100)_50%,#ffffff)_padding-box,conic-gradient(from_var(--border-angle),theme(colors.slate.300/.48)_80%,_theme(colors.blue.300)_86%,_theme(colors.blue.100)_90%,_theme(colors.blue.300)_94%,_theme(colors.slate.300/.48))_border-box] 
      dark:bg-slate-800/50
      hover:dark:[background:linear-gradient(45deg,#172033,theme(colors.slate.800)_50%,#172033)_padding-box,conic-gradient(from_var(--border-angle),theme(colors.slate.600/.48)_80%,_theme(colors.indigo.500)_86%,_theme(colors.indigo.300)_90%,_theme(colors.indigo.500)_94%,_theme(colors.slate.600/.48))_border-box]`,
      )}
    >
      {children}
    </div>
  );
};

export default GalaxyBorderCard;

"use client";
import React, { useState } from "react";
import Link from "antd/es/typography/Link";
import useSnowflakes from "@components/events/decorate/falldown-snowflake";
import GalaxyBorderCard from "../../decor/galaxy-border-card";
import dynamic from "next/dynamic";

const QrCode = dynamic(() => import("@/ui/animate-icons/qr-code"), {
  ssr: false, // ✅ Ensures it's only loaded on the client
});
const CursorRays = dynamic(() => import("@/ui/animate-icons/cursor-rays"), {
  ssr: false, // ✅ Ensures it's only loaded on the client
});
const LinesY = dynamic(() => import("@/ui/animate-icons/lines-y"), {
  ssr: false, // ✅ Ensures it's only loaded on the client
});
const Gear = dynamic(() => import("@/ui/animate-icons/gear"), {
  ssr: false, // ✅ Ensures it's only loaded on the client
});
const SparklesIcon = dynamic(() => import("@/ui/animate-icons/sparkles"), {
  ssr: false, // ✅ Ensures it's only loaded on the client
});

interface IProps {
  url: string;
  title: string;
  icon: "qr-code" | "cursor-rays" | "lines-y" | "sparkles" | "default";
}

const HighlightItem: React.FC<IProps> = ({ url, title, icon }: IProps) => {
  const disabledSnowflakes = true;
  const { snowflakes, createSnowflake } = useSnowflakes(); // Use the custom hook
  const [hovered, setHovered] = useState(false);
  let IconWidget = CursorRays;
  if (icon === "qr-code") {
    IconWidget = QrCode;
  } else if (icon === "lines-y") {
    IconWidget = LinesY;
  } else if (icon === "default") {
    IconWidget = Gear;
  } else if (icon === "sparkles") {
    IconWidget = SparklesIcon;
  }

  return (
    <Link
      href={url}
      onMouseMove={createSnowflake}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
    >
      <GalaxyBorderCard className="app-icon rounded-full">
        <div className="group flex h-12 w-auto items-center justify-center overflow-hidden px-4 font-semibold">
          <IconWidget data-hovered={hovered} className="h-5 w-5" />
          <span className="ml-2 whitespace-nowrap">{title}</span>
        </div>
      </GalaxyBorderCard>

      {!disabledSnowflakes && snowflakes}
    </Link>
  );
};

export default HighlightItem;

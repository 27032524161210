import React, { useEffect } from "react";
import style from "./snowflake.module.scss";

interface SnowflakeProps {
  left: number; // X position
  top: number; // Y position
  duration: number; // Animation duration
  onAnimationEnd: () => void; // Callback to remove the snowflake
}

const Snowflake: React.FC<SnowflakeProps> = ({
  left,
  top,
  duration,
  onAnimationEnd,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onAnimationEnd();
    }, duration * 1000); // Convert to milliseconds

    return () => clearTimeout(timer);
  }, [duration, onAnimationEnd]);

  return (
    <div
      className={style.snowflake}
      style={{
        left: `${left}px`,
        top: `${top}px`,
        animationDuration: `${duration}s`,
      }}
    >
      ❄️
    </div>
  );
};

export default Snowflake;

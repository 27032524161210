"use client";
import { socialType } from "@/src/lib/constants";
import { Facebook, Twitter } from "lucide-react";
import { FacebookShareButton, TwitterShareButton } from "next-share";
import { useEffect, useState } from "react";

interface IProps {
  slug: string;
  type: string;
  description?: string;
}

const SocialShareButton = ({ slug, description, type }: IProps) => {
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    // Check if the code is running on the client side
    if (process) {
      // Access the current page URL using window.location
      const hostUrl =
        window.location.href && window.location.href.endsWith("/")
          ? window.location.href.slice(0, window.location.href.length - 1)
          : "";
      setShareUrl(`${hostUrl}/${slug}`);
    }
  }, []);

  if (type === socialType.facebook) {
    return (
      <FacebookShareButton
        url={shareUrl}
        quote={description}
        hashtag={"#1hour.dev"}
      >
        <Facebook className="facebook icon-facebook" size={16} />
      </FacebookShareButton>
    );
  }

  return (
    <TwitterShareButton url={shareUrl} title={description}>
      <Twitter className="twitter icon-twitter" size={16} />
    </TwitterShareButton>
  );
};

export default SocialShareButton;

import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/app-items/highlight-tools/highlight-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/app-items/social-button/social-share-button.component.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/blur-image.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/post/highlight/highlight-posts.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/public/home/list-post-by-category.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/public/home/nav-category/nav-category-client.tsx");
